<template>
    <section v-if="idGrupo != 0">
        <div v-for="(t,k) in grupoTienda" :key="k" class="row mx-0 align-items-center px-3">
            <img :src="t.imagen" class="rounded-circle border obj-cover" width="42" height="42" />
            <div class="col px-2">
                <p class="f-16 text-general">{{ t.nombre }}</p>
                <p>
                    <i class="icon-cedis f-14 text-general" />
                    <span class="f-14 mx-1">{{ t.cedis }}</span>
                </p>
            </div>
            <i v-if="t.favorito != null" class="icon-star f-20 text-warning" />
            <el-tooltip placement="bottom" content="Ver horario atencion" effect="light">
                <i class="icon-calendar f-18 text-general cr-pointer" @click="horariosVer(t)" />
            </el-tooltip>
            <el-popover ref="popoverLeechero" placement="bottom" effect="light" popper-class="p-2 br-8">
                <div class="row mx-0 item p-2 cr-pointer" @click="asignarPrincipal(t.id)">
                    Asignar como principal
                </div>
                <div class="row mx-0 item p-2 cr-pointer" @click="quitarLeechero(t.id)">
                    Quitar {{ $config.vendedor }}
                </div>
                <i slot="reference" class="icon-dots-vertical cr-pointer f-18 text-general" />
            </el-popover>
        </div>

        <hr class="border mx-2 my-2" />

        <div v-for="(gg, key) in grupoGeocerca" :key="'geo'+key" class="row mx-0 px-3 align-items-center">
            <i class="icon-geocerca f-18 text-general" />
            <div class="col px-2 f-16 text-general">
                {{ gg.geocercas }}
            </div>
            <i class="icon-delete-trash f-18 cr-pointer text-general" @click="modalEliminarGeocerca(gg.id)" />
        </div>
        <div v-if="grupoGeocerca.length == 0" class="row mx-0 justify-center mt-5">
            <div class="col-auto text-general text-center">
                Aun no se han asignado <br /> vendedores ni geocercas a este <br /> grupo
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalQuitarLeechero" titulo="Eliminar" no-aceptar adicional="Quitar" @adicional="eliminarTendero">
            <div class="row mx-0 justify-center">
                <p class="col-auto text-general">
                    ¿Desea quitar este {{ $config.vendedor }}?
                </p>
            </div>
        </modal>
        <modal ref="modalQuitarGeocercas" titulo="Quitar Geocerca" no-aceptar adicional="Quitar" @adicional="eliminarGeocercaGrupo">
            <div class="row mx-0 justify-center">
                <p class="col-auto text-general">
                    ¿Desea quitar esta geocerca del grupo?
                </p>
            </div>
        </modal>
    </section>
    <section v-else>
        <div class="row mx-0 justify-center mt-5">
            <div class="col-auto text-black-50 text-center">
                Por favor seleccione <br /> un grupo
            </div>
        </div>
    </section>
</template>
<script>
import Geocerca from '~/services/geocerca/geocerca'
export default {
    props: {
        idGrupo:{
            type: Number,
            required: true
        },
    },
    data(){
        return {
            grupoGeocerca: [],
            idGeocercaGrupo: null,
            grupoTienda: [],
            idGrupoTienda: null
        }
    },
    watch: {
        idGrupo(){
            this.getGeocercaGrupoGeocerca()
            this.getGeocercaGrupoTienda()
        }
    },
    mounted(){
        if (this.idGrupo.length != 0){
            this.getGeocercaGrupoGeocerca()
            this.getGeocercaGrupoTienda()
        }
    },
    methods: {
        quitarLeechero(id){
            // this.$refs.popoverLeechero.doClose()
            this.$refs.modalQuitarLeechero.toggle();
            this.idGrupoTienda = id
        },
        modalEliminarGeocerca(id){
            this.$refs.modalQuitarGeocercas.toggle()
            this.idGeocercaGrupo = id
        },
        async getGeocercaGrupoGeocerca(){
            try {
                const {data} = await Geocerca.getGeocercaGrupoGeocerca({idGrupo: this.idGrupo})
                this.grupoGeocerca = data?.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminarGeocercaGrupo(){
            try {
                await Geocerca.eliminarGeocercaGrupo(this.idGeocercaGrupo)
                this.notificacion('','geocerca eliminado correctamente','success')
                this.$refs.modalQuitarGeocercas.toggle()
                this.getGeocercaGrupoGeocerca()
                this.$emit("cargar");
            } catch (e){
                this.error_catch(e)
            }
        },
        async getGeocercaGrupoTienda(){
            try {
                const {data} = await Geocerca.getGeocercaGrupoTienda({idGrupo: this.idGrupo})
                this.grupoTienda = data?.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async asignarPrincipal(idTienda){
            try {
                await Geocerca.asignarPrincipal({idGrupo: this.idGrupo,idGrupotienda: idTienda})
                this.notificacion('','Actualizado correctamente','success')
                this.getGeocercaGrupoTienda()
            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminarTendero(){
            try {
                await Geocerca.eliminarGeocercaGrupoTienda(this.idGrupoTienda)
                this.notificacion('','Eliminado correctamente','success')
                this.$refs.modalQuitarLeechero.toggle()
                this.getGeocercaGrupoTienda()
                this.$emit("cargar");
            } catch (e){
                this.error_catch(e)
            }
        },
        horariosVer(tendero){
            this.$emit('horarios',tendero)
        }
    }
}
</script>
